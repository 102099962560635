import React from "react"

function ArrowIcon({ size = 32, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" {...props}>
      <path d="M30.707 15.293L21 5.586 19.586 7l8 8H1v2h26.586l-8 8L21 26.414l9.707-9.707a.999.999 0 000-1.414z" />
    </svg>
  )
}

export default ArrowIcon
