import * as React from "react"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import DataTable from "../DataTable"
import { useState } from "react"
import { theme } from "../../styles/theme"
import ConcreteWritings from "../ConcreteWritings"
import CustomTable from "../CustomTable"
import styled from "styled-components"

// ;<div
//   className="trafficLight"
//   style={{
//     backgroundColor: "black",
//     width: "109px",
//     border: "none",
//     borderRadius: "20px",
//     margin: "auto",
//     boxShadow: "0 2px 6px 0",
//     marginBottom: "6px",
//   }}
// >
//   <button
//     style={{
//       backgroundColor: trafficLight.red ? "red" : "grey",
//       height: "30px",
//       width: "30px",
//       border: "none",
//       borderRadius: "1000px",
//       color: trafficLight.red ? "red" : "black",
//       boxShadow: "0 2px 8px 0",
//       margin: "3px 3px 3px 3px",
//       paddingBottom: "2px",
//     }}
//     onClick={() =>
//       setTrafficLight({ red: true, yellow: false, green: false })
//     }
//   />

//   <button
//     style={{
//       backgroundColor: trafficLight.yellow ? "yellow" : "grey",
//       height: "30px",
//       width: "30px",
//       border: "none",
//       borderRadius: "1000px",
//       color: trafficLight.yellow ? "yellow" : "black",
//       boxShadow: "0 2px 8px 0",
//       margin: "3px 3px 3px 3px",
//       paddingBottom: "2px",
//     }}
//     onClick={() =>
//       setTrafficLight({ red: false, yellow: true, green: false })
//     }
//   />

//   <button
//     style={{
//       backgroundColor: trafficLight.green ? "green" : "grey",
//       height: "30px",
//       width: "30px",
//       border: "none",
//       borderRadius: "1000px",
//       color: trafficLight.green ? "green" : "black",
//       boxShadow: "0 2px 8px 0",
//       margin: "3px 3px 3px 3px",
//       paddingBottom: "2px",
//     }}
//     onClick={() =>
//       setTrafficLight({ red: false, yellow: false, green: true })
//     }
//   />
// </div>
// Layout
export const DashboardTabsWrapper = styled.div``
// Layout-end

const overviewHeader = [
  "Student",
  "Time practiced [min:sec]",
  "Feedback time [min:sec]",
  "Total Time [min:sec]",
  "Words written",
  "Correctly spelled words",
  "Average attempts",
  "PKW2 *",
  "PKW3 *",
  "PKW4 *",
  "PKW5 *",
  "PKW6 *",
  "PKW7 *",
  "PKW8 *",
  "PKW9 *",
  "PKW problem area",
]

const writingsHeader = [
  "Student",
  "Word",
  "Input",
  "Correct",
  "Attempt",
  "PKW",
  "Date",
  "Time",
]

const trafficLightIdx = [1, 2, 3, 4, 5, 6]

function DashboardTabsDisplay({
  overviewData,
  writingsData,
  overviewOrder,
  writingsOrder,
}) {
  console.log("DATEN: ", overviewData)
  const [tabIndex, setTabIndex] = useState(0)
  const [trafficLight, setTrafficLight] = useState({
    red: false,
    yellow: false,
    green: false,
  })

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex)
  }

  return (
    <DashboardTabsWrapper>
      <Box>
        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab
              label="Overview"
              sx={{
                fontSize: theme.fontSizes.regular,
                fontFamily: "Grundschrift Bold",
                color: theme.colors.light,
              }}
            />
            <Tab
              label="Writings"
              sx={{
                fontSize: theme.fontSizes.regular,
                fontFamily: "Grundschrift Bold",
                color: theme.colors.light,
              }}
            />
          </Tabs>
        </Box>
        <Box
          sx={{
            padding: 2,
            fontSize: theme.fontSizes.regular,
            fontFamily: "Grundschrift Bold",
            color: theme.colors.light,
          }}
        >
          {tabIndex === 0 && (
            <Box>
              <CustomTable
                header={overviewHeader}
                rows={overviewData}
                columnOrder={overviewOrder}
                hasTrafficLight={true}
                trafficLightIdx={trafficLightIdx}
              />
              <br />
              <footer>* on first attempt</footer>
            </Box>
          )}
          {tabIndex === 1 && (
            <Box>
              <CustomTable
                header={writingsHeader}
                rows={writingsData}
                columnOrder={writingsOrder}
                hasTrafficLight={false}
              />
            </Box>
          )}
        </Box>
      </Box>
    </DashboardTabsWrapper>
  )
}

export default DashboardTabsDisplay
